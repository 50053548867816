<template>
  <Container
    :title="$t('autoInvest.autoInvestStrategyEdit')"
    :back-to="{ name: 'AutoInvestStrategyDetail', params: { id } }"
  >
    <AutoInvestStrategyForm :id="id" v-model="filters" @update:autoInvestStrategy="previewInvestments"/>
    <v-row class="mb-6">
      <v-col>
        <v-card elevation="2">
          <v-card-title>{{ $t('autoInvest.matchingLoans') }} ({{ countLoans }})</v-card-title>
          <v-card-subtitle v-text="$t('autoInvest.loanListDescription')"/>
          <v-card-text>
            <LoanList ref="loanList" :items="loans" :loading="loadingLoans" :count="countLoans" :page.sync="pageLoans" :size.sync="sizeLoans"/>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card elevation="2">
          <v-card-title>{{ $t('autoInvest.investmentsToBeCreatedWithThisStrategy') }} ({{ countInvestments }})</v-card-title>
          <v-card-subtitle v-text="$t('autoInvest.investmentListDescription')"/>
          <v-card-text>
            <InvestmentList v-if="countInvestments > 0 || loadingInvestments" :items="investments" :loading="loadingInvestments" :short="true" :count="countInvestments" :page.sync="pageInvestments" :size.sync="sizeInvestments"/>
            <AutoInvestCriteriaList v-else :count-investments="countInvestments" :loading-investments="loadingInvestments" :loans="loans" :monetary-constraints="monetaryConstraints" :strategy-id="id"/>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </Container>
</template>

<script>
import Vue from 'vue'
import AutoInvestStrategyForm from "@/modules/autoinvest/pages/AutoInvestStrategyForm"
import LoanList from '../../loans/components/LoanList.vue'
import InvestmentList from "../../investments/components/InvestmentList.vue"
import AutoInvestCriteriaList from '../components/AutoInvestCriteriaList.vue'

const debounceDelay = 300

export default Vue.extend({
  name: "AutoInvestStrategyEditPage",
  components: {
    AutoInvestStrategyForm,
    LoanList,
    InvestmentList,
    AutoInvestCriteriaList,
  },
  props: {
    id: { type: String, default: null },
  },
  data() {
    return {
      loadingLoans: false,
      pageLoans: 1,
      sizeLoans: 10,
      countLoans: 0,
      loans: [],
      loadingInvestments: false,
      pageInvestments: 1,
      sizeInvestments: 100,
      countInvestments: 0,
      investments: [],

      debounceTimerLoans: null,
      debounceTimerStrategy: null,

      filters: {},
      monetaryConstraints: {
        maxPerInvestment: 10,
        targetPortfolioSize: 100,
      },
      abortControllerLoans: undefined,
      abortControllerInvestments: undefined,
    }
  },
  computed: {
    investmentAccountId() {
      return this.$store.state.account.id
    },
  },
  watch: {
    pageLoans() { this.fetchLoans(); this.$vuetify.goTo(this.$refs.loanList) },
    sizeLoans() { this.fetchLoans() },
    filters() {
      if (this.pageLoans !== 1) {
        this.pageLoans = 1 // resets page number and trigger another watcher to avoid two requests
      } else {
        this.fetchLoans() // just fetch the loans, page is already first
      }
    },
    investmentAccountId() {
      this.fetchLoans()
    },
  },
  mounted() {
    this.fetchLoans()
    this.previewInvestments()
  },
  methods: {
    fetchLoans() {
      clearTimeout(this.debounceTimerLoans)

      this.debounceTimerLoans = setTimeout(() => {
        this.loadingLoans = true
        this.filters.include_loans_already_invested_in = this.filters.include_loans_already_invested_in || ''
        if (this.abortControllerLoans !== undefined) { this.abortControllerLoans.abort() }
        this.abortControllerLoans = new AbortController()

        this.$http.get('/loans/', {
          signal: this.abortControllerLoans.signal,
          params: {
            page: this.pageLoans,
            size: this.sizeLoans,
            investment_account: this.investmentAccountId,
            hide_fully_invested: true,
            ...this.filters,
          },
        }).then((response) => {
          this.loadingLoans = false
          this.loans = response.data.results
          this.countLoans = response.data.count
        }).catch((e) => {
          if (e.code != "ERR_CANCELED") {
            // Catch also gets called on cancel, but we don't
            // want to stop the loading animation since we just
            // replaced previous request with a new one
            this.loadingInvestments = false
          }
        })
      }, debounceDelay)
    },
    previewInvestments(autoInvestStrategy = {}) {
      clearTimeout(this.debounceTimerStrategy)
      this.debounceTimerStrategy = setTimeout(() => {
        this.investments = []
        this.countInvestments = 0
        this.loadingInvestments = true
        if (autoInvestStrategy?.target_portfolio_size) {
          this.monetaryConstraints.targetPortfolioSize = autoInvestStrategy.target_portfolio_size
          this.monetaryConstraints.maxPerInvestment    = autoInvestStrategy.maximum_amount_per_investment
        }
        if (this.abortControllerInvestments !== undefined) { this.abortControllerInvestments.abort() }
        this.abortControllerInvestments = new AbortController()

        this.$http.post('autoinvest_strategies/preview_autoinvest_strategy/', {
          ...autoInvestStrategy,
        }, {
          signal: this.abortControllerInvestments.signal,
          params: {
            page: this.pageInvestments,
            size: this.sizeInvestments,
            investment_account: this.investmentAccountId,
            autoinvest_strategy: this.id,
          },
        }).then((response) => {
          this.loadingInvestments = false
          this.investments = response.data || []
          this.countInvestments = response.data.length || 0
        }).catch((e) => {
          if (e.code != "ERR_CANCELED") {
            // Catch also gets called on cancel, but we don't
            // want to stop the loading animation since we just
            // replaced previous request with a new one
            this.loadingInvestments = false
          }
        })
      }, debounceDelay)
    },
  },
})
</script>
