<template>
  <v-row>
    <v-col>
      <v-card elevation="2" :loading="loading">
        <v-card-title v-text="$t('autoInvest.autoInvestStrategy')"/>
        <v-card-text>
          <v-form ref="form" v-model="form" :disabled="loading" lazy-validation>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field v-model="autoinvest_strategy.strategy_name"
                              :label="$t('autoInvest.strategyName')"
                              :rules="strategyNameRules"
                              filled
                >
                  <v-tooltip slot="append-outer" bottom max-width="400">
                    <template #activator="{ on }">
                      <v-icon slot="activator" color="primary" dark v-on="on">
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span v-text="$t('autoInvest.tooltips.strategyName')"/>
                  </v-tooltip>
                </v-text-field>
                <v-text-field v-model="autoinvest_strategy.maximum_amount_per_investment"
                              type="number"
                              :label="$t('autoInvest.maximumAmountPerInvestment')"
                              :rules="moneyInputFieldsRules(15000)"
                              filled
                >
                  <v-tooltip slot="append-outer" bottom max-width="400">
                    <template #activator="{ on }">
                      <v-icon slot="activator" color="primary" dark v-on="on">
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span v-text="$t('autoInvest.tooltips.maximumAmountPerInvestment')"/>
                  </v-tooltip>
                </v-text-field>
                <v-text-field v-model="autoinvest_strategy.target_portfolio_size"
                              type="number"
                              :label="$t('autoInvest.targetPortfolioSize')"
                              :rules="moneyInputFieldsRules(1000000)"
                              filled
                >
                  <v-tooltip slot="append-outer" bottom max-width="400">
                    <template #activator="{ on }">
                      <v-icon slot="activator" color="primary" dark v-on="on">
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span v-text="$t('autoInvest.tooltips.targetPortfolioSize')"/>
                  </v-tooltip>
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <div class="py-0 mb-2">
                  <RangeSelectorSlider v-model="investor_interest_range" min="1" max="20" step="0.1"
                                       :initial-range="[autoinvest_strategy.investor_interest_min * 100, autoinvest_strategy.investor_interest_max * 100]"
                                       :tooltip="$t('autoInvest.investorInterestPercentDescription')"
                                       :title="$t('autoInvest.investorInterestPercent')"
                                       @input="set_investor_interest_values"
                  />
                </div>
                <br>
                <div class="py-0 mb-2">
                  <RangeSelectorSlider v-model="remaining_loan_term_range" min="0" max="120"
                                       :initial-range="[autoinvest_strategy.remaining_loan_term_min, autoinvest_strategy.remaining_loan_term_max]"
                                       :tooltip="$t('autoInvest.remainingLoanTermsDescription')"
                                       :title="$t('autoInvest.remainingLoanTerm')"
                                       @input="set_remaining_loan_term_values"
                  />
                </div>
                <br>
                <div class="py-0 mb-2">
                  <RangeSelectorSlider v-model="borrower_aprc_range" min="1" max="3000" step="1"
                                       :initial-range="[autoinvest_strategy.borrower_aprc_min * 100, autoinvest_strategy.borrower_aprc_max * 100]"
                                       :tooltip="$t('loanDetails.borrowerAprcPercentDescription')"
                                       :title="$t('loanDetails.borrowerAprcPercent')"
                                       @input="set_borrower_aprc_values"
                  />
                </div>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-container>
                  <v-row align="center">
                    <v-divider/>
                    <v-btn
                      class="mx-2"
                      color="primary lighten-3"
                      outlined
                      @click="showAdvancedSettings = !showAdvancedSettings"
                    >
                      <v-icon v-if="showAdvancedSettings" left>mdi-chevron-up</v-icon>
                      <v-icon v-else left>mdi-chevron-down</v-icon>
                      {{ $t('autoInvest.additionalOptions') }}
                    </v-btn>
                    <v-divider/>
                  </v-row>
                </v-container>
              </v-col>
              <v-col cols="12">
                <v-expand-transition>
                  <section v-show="showAdvancedSettings">
                    <v-row>
                      <v-col cols="12" md="6">
                        <div class="py-4">
                          <RangeSelectorSlider v-model="borrower_interest_range" min="1" max="1000" step="1"
                                               :initial-range="[autoinvest_strategy.borrower_interest_min * 100, autoinvest_strategy.borrower_interest_max * 100]"
                                               :tooltip="$t('loanDetails.borrowerInterestRatePercentDescription')"
                                               :title="$t('loanDetails.borrowerInterestRatePercent')"
                                               @input="set_borrower_interest_values"
                          />
                        </div>
                        <div class="py-4">
                          <RangeSelectorSlider v-model="loan_term_range" min="0" max="120"
                                               :initial-range="[autoinvest_strategy.loan_term_min, autoinvest_strategy.loan_term_max]"
                                               :tooltip="$t('autoInvest.loanTermsDescription')"
                                               :title="$t('autoInvest.loanTerms')"
                                               @input="set_loan_term_values"
                          />
                        </div>
                        <div class="py-3">
                          <BaseMultiselect v-model="autoinvest_strategy.countries"
                                           :items="country_options"
                                           :loading="loadingFilterHints"
                                           :hint="$t('autoInvest.countries')"
                                           multi-select-data-type="country"
                          />
                        </div>
                        <div class="py-3">
                          <BaseMultiselect v-model="autoinvest_strategy.loan_originators"
                                           :items="loan_originator_options"
                                           :loading="loadingFilterHints"
                                           :hint="$t('autoInvest.loanOriginators')"
                          />
                        </div>
                        <div class="py-3">
                          <BaseMultiselect v-model="autoinvest_strategy.loan_types"
                                           :items="loanTypes"
                                           item-text="name"
                                           item-value="code"
                                           :hint="$t('autoInvest.loanTypes')"
                                           :loading="loadingFilterHints"
                                           multi-select-data-type="loanType"
                          />
                        </div>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-row class="d-flex align-center py-6">
                          <v-col cols="6">
                            <span v-text="$t('autoInvest.marketTypePrimary')"/>
                          </v-col>
                          <v-col cols="6" class="py-0">
                            <div class="switch-row">
                              <v-switch v-model="autoinvest_strategy.market_type_primary" :loading="loading" disabled/>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row class="d-flex align-center py-0">
                          <v-col cols="6">
                            <span v-text="$t('autoInvest.marketTypeSecondary')"/>
                          </v-col>
                          <v-col cols="6" class="py-0">
                            <v-tooltip bottom>
                              <template #activator="{ on, attrs }">
                                <div class="switch-row d-inline-block" v-bind="attrs" v-on="on">
                                  <v-switch v-model="autoinvest_strategy.market_type_secondary"
                                            :loading="loading"
                                            v-bind="attrs"
                                            disabled
                                            v-on="on"
                                  />
                                </div>
                              </template>
                              <span v-text="$t('common.featureComingSoon')"/>
                            </v-tooltip>
                            <v-tooltip slot="append-outer" bottom max-width="400">
                              <template #activator="{ on }">
                                <v-icon slot="activator" color="primary" dark v-on="on">
                                  mdi-information-outline
                                </v-icon>
                              </template>
                              <span v-text="$t('autoInvest.tooltips.marketTypeSecondary')"/>
                            </v-tooltip>
                          </v-col>
                        </v-row>
                        <v-row class="d-flex align-center py-6">
                          <v-col cols="6">
                            <span v-text="$t('autoInvest.reinvest')"/>
                          </v-col>
                          <v-col cols="6" class="py-0">
                            <div class="switch-row d-inline-block">
                              <v-switch v-model="autoinvest_strategy.reinvest" :loading="loading"/>
                            </div>
                            <v-tooltip slot="append" bottom max-width="400">
                              <template #activator="{ on }">
                                <v-icon slot="activator" color="primary" dark v-on="on">
                                  mdi-information-outline
                                </v-icon>
                              </template>
                              <span v-text="$t('autoInvest.tooltips.reinvest')"/>
                            </v-tooltip>
                          </v-col>
                        </v-row>
                        <v-row class="d-flex align-center py-1">
                          <v-col cols="6">
                            <span v-text="$t('autoInvest.includeLoansAlreadyInvestedIn')"/>
                          </v-col>
                          <v-col cols="6" class="py-0">
                            <div class="switch-row d-inline-block">
                              <v-switch v-model="autoinvest_strategy.include_loans_already_invested_in" :loading="loading"/>
                            </div>
                            <v-tooltip slot="append" bottom max-width="400">
                              <template #activator="{ on }">
                                <v-icon slot="activator" color="primary" dark v-on="on">
                                  mdi-information-outline
                                </v-icon>
                              </template>
                              <span v-text="$t('autoInvest.tooltips.includeLoansAlreadyInvestedIn')"/>
                            </v-tooltip>
                          </v-col>
                        </v-row>
                        <v-row class="py-3">
                          <v-col cols="6">
                            <datepicker
                              v-model="autoinvest_strategy.loan_issue_date_from"
                              :label="$t('autoInvest.loanIssueDateFrom')"
                            />
                          </v-col>
                          <v-col cols="6">
                            <datepicker
                              v-model="autoinvest_strategy.loan_issue_date_to"
                              :label="$t('autoInvest.loanIssueDateTo')"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </section>
                </v-expand-transition>
                <v-checkbox v-model="isConsented" :label="$t('autoInvest.consent')"/>
                <v-btn large :dark="isConsented" class="mt-3 mr-3" :disabled="!isConsented" @click="submit" v-text="submitButtonName"/>
                <v-btn v-if="id" large class="mt-3" :to="{ name: 'AutoInvestStrategyDetail', params: { id } }" exact-path v-text="$t('autoInvest.cancel')"/>
                <v-btn v-else large class="mt-3" :to="{ name: 'AutoInvest' }" exact-path v-text="$t('autoInvest.cancel')"/>
                <ConfirmActivation v-if="value" v-model="smsDialog" :strategy="autoinvest_strategy.id ? autoinvest_strategy : { id }" @closeSmsDialog="closeSmsDialog"/>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Vue from 'vue'

import RangeSelectorSlider from "../../common/components/RangeSelectorSlider"
import ConfirmActivation from "../components/ConfirmActivation.vue"

export default Vue.extend({
  name: 'AutoInvestStrategyForm',
  components: {
    RangeSelectorSlider,
    ConfirmActivation,
  },
  props: {
    id: { type: String, default: null },
    value: { type: Object, default: null },
  },
  data() {
    return {
      form:    false,
      loading: false,
      autoinvest_strategy: {
        strategy_name:                      "",
        countries:                          [],
        loan_originators:                   [],
        loan_types:                         [],
        maximum_amount_per_investment:      "10",
        target_portfolio_size:              "100",
        investor_interest_min:              0.01,
        investor_interest_max:              0.2,
        borrower_interest_min:              0.01,
        borrower_interest_max:              10.00,
        borrower_aprc_min:                  0.01,
        borrower_aprc_max:                  30.00,
        loan_term_min:                      0,
        loan_term_max:                      120,
        remaining_loan_term_min:            0,
        remaining_loan_term_max:            120,
        market_type_primary:                true,
        market_type_secondary:              false,
        reinvest:                           true,
        include_loans_already_invested_in:  true,
        loan_issue_date_from:               null,
        loan_issue_date_to:                 null,
      },
      filters:                    {},
      investor_interest_range:    [1, 20],
      borrower_interest_range:    [1, 1000],
      borrower_aprc_range:        [1, 3000],
      loan_term_range:            [0, 120],
      remaining_loan_term_range:  [0, 120],
      loadingFilterHints:         false,
      country_options:            [],
      loan_originator_options:    [],
      loan_type_options:          [],
      showAdvancedSettings:       false,
      isConsented:                false,
      smsDialog:                  false,
    }
  },
  computed: {
    investmentAccount() {
      return this.$store.state.account
    },
    submitButtonName() {
      return this.id ? this.$t('autoInvest.save') : this.$t('autoInvest.create')
    },
    loanTypes() {
      return this.loan_type_options.map(code => {
        return { code, name: this.$t(`loanDetails.loanTypes.${code}`) }
      })
    },
    strategyNameRules() {
      return [
        (v) => v.length < 100 || this.$t('errors.tooLong', { charLimit: 100 }),
      ]
    },
  },
  watch: {
    id: {
      immediate: true,
      handler() {
        this.fetch()
        this.getFilterHints()
      },
    },
    autoinvest_strategy: {
      deep: true,
      handler() {
        const filters = {
          country:                           this.autoinvest_strategy.countries.toString(),
          loan_originator:                   this.autoinvest_strategy.loan_originators.toString(),
          loan_type:                         this.autoinvest_strategy.loan_types.toString(),
          investor_interest__gte:            this.autoinvest_strategy.investor_interest_min,
          investor_interest__lte:            this.autoinvest_strategy.investor_interest_max,
          borrower_interest__gte:            this.autoinvest_strategy.borrower_interest_min,
          borrower_interest__lte:            this.autoinvest_strategy.borrower_interest_max,
          aprc__gte:                         this.autoinvest_strategy.borrower_aprc_min,
          aprc__lte:                         this.autoinvest_strategy.borrower_aprc_max,
          loan_term__gte:                    this.autoinvest_strategy.loan_term_min,
          loan_term__lte:                    this.autoinvest_strategy.loan_term_max,
          maturity_date__gte:                this.autoinvest_strategy.remaining_loan_term_min,
          maturity_date__lte:                this.autoinvest_strategy.remaining_loan_term_max,
          include_loans_already_invested_in: this.autoinvest_strategy.include_loans_already_invested_in,
          value_date__gte:                   this.autoinvest_strategy.loan_issue_date_from,
          value_date__lte:                   this.autoinvest_strategy.loan_issue_date_to,
        }
        if (!this.$refs.form.validate()) {
          // Handle edge case when opening existing strategy for edit -
          // values are correct, because they were stored, but vuetify cannot see them
          if (this.$refs.form.value) { this.$emit("input", filters) }
          return
        }
        this.$emit("input", filters)
        this.$emit("update:autoInvestStrategy", this.autoinvest_strategy)
      },
    },
  },
  methods: {
    moneyInputFieldsRules(max_value) {
      return [
        (v) => { return (!!v) || this.$t('errors.fieldRequired') },
        (v) => { return (+(v.toString().replace(/^0+(?!\.|$)/, '')) >= 10)       || this.$t('errors.isBiggerEqualThan', { amount: 10 }) },
        (v) => { return (+(v.toString().replace(/^0+(?!\.|$)/, '')) < max_value) || this.$t('errors.isLessThan',        { amount: max_value }) },
        (v) => { return (+(v.toString().split('.')[1] ? (v.toString().split('.')[1].length) : 0) <= 2) || this.$t('errors.decimalAmount', { amount: 2 }) },
      ]
    },
    set_investor_interest_values(value) {
      this.autoinvest_strategy.investor_interest_min = (value[0] / 100).toFixed(4)
      this.autoinvest_strategy.investor_interest_max = (value[1] / 100).toFixed(4)
      this.$set(this.filters, 'investor_interest_min', (value[0] / 100).toFixed(4))
      this.$set(this.filters, 'investor_interest_max', (value[1] / 100).toFixed(4))
    },
    set_borrower_interest_values(value) {
      this.autoinvest_strategy.borrower_interest_min = (value[0] / 100).toFixed(4)
      this.autoinvest_strategy.borrower_interest_max = (value[1] / 100).toFixed(4)
      this.$set(this.filters, 'borrower_interest_min', (value[0] / 100).toFixed(4))
      this.$set(this.filters, 'borrower_interest_max', (value[1] / 100).toFixed(4))
    },
    set_borrower_aprc_values(value) {
      this.autoinvest_strategy.borrower_aprc_min = (value[0] / 100).toFixed(4)
      this.autoinvest_strategy.borrower_aprc_max = (value[1] / 100).toFixed(4)
      this.$set(this.filters, 'borrower_aprc_min', (value[0] / 100).toFixed(4))
      this.$set(this.filters, 'borrower_aprc_max', (value[1] / 100).toFixed(4))
    },
    set_loan_term_values(value) {
      this.autoinvest_strategy.loan_term_min = value[0]
      this.autoinvest_strategy.loan_term_max = value[1]
      this.$set(this.filters, 'loan_term_min', value[0])
      this.$set(this.filters, 'loan_term_max', value[1])
    },
    set_remaining_loan_term_values(value) {
      this.autoinvest_strategy.remaining_loan_term_min = value[0]
      this.autoinvest_strategy.remaining_loan_term_max = value[1]
      this.$set(this.filters, 'remaining_loan_term_min', value[0])
      this.$set(this.filters, 'remaining_loan_term_max', value[1])
    },
    fetch() {
      if (!this.id) {
        return
      }
      this.loading = true
      this.$http.get(`/autoinvest_strategies/${this.id}/`)
        .then((response) => {
          this.loading = false
          this.autoinvest_strategy = response.data
          this.isConsented = true
        })
        .catch(() => {
          this.loading = false
        })
    },
    submit() {
      if (!this.$refs.form.validate()) {
        return
      }
      this.loading = true
      if (this.id) {
        this.update()
      } else {
        this.create()
      }
    },
    update() {
      this.autoinvest_strategy.is_active = false
      this.$http.patch(`/autoinvest_strategies/${this.id ?? this.autoinvest_strategy.id}/`, this.autoinvest_strategy)
        .then(() => {
          this.loading = false
          if (this.$auth.user().verification === 'approved') {
            this.smsDialog = true
          } else {
            const pushMessageText = this.$t('autoInvest.thisStrategyIsNotActive') + '\n' + this.$t('autoInvest.finishVerification')
            this.$store.commit('pushMessage', {
              icon: 'mdi-robot-dead',
              text: pushMessageText,
            })
            this.closeSmsDialog()
          }
        }).catch(() => {
          this.loading = false
        })
    },
    create() {
      this.loading = true
      this.$http.post('/autoinvest_strategies/', {
        investment_account: this.investmentAccount.id,
        ...this.autoinvest_strategy,
        ...this.filters,
      }).then((response) => {
        this.loading = false

        // src: https://stackoverflow.com/a/50854892
        Object.assign(this.$data, this.$options.data.apply(this)) // resets data property with initial values
        this.$refs.form.resetValidation()
        // NB: `this.$refs.form.reset()` cannot be used here, as it simply clears the values, without setting them to default.
        // This is fine for strategy name, but also removes maximum_amount_per_investment and similar

        this.autoinvest_strategy.id = response.data.id
        if (this.$auth.user().verification === 'approved') {
          this.smsDialog = true
        } else {
          const pushMessageText = this.$t('autoInvest.thisStrategyIsNotActive') + '\n' + this.$t('autoInvest.finishVerification')
          this.$store.commit('pushMessage', {
            icon: 'mdi-robot-dead',
            text: pushMessageText,
          })
          this.closeSmsDialog()
        }
      })
        .catch(() => {
          this.loading = false
        })
    },
    getFilterHints() {
      this.loadingFilterHints = true
      this.$http
        .get(`/loans/filter_hints/`)
        .then((result) => {
          this.country_options = result.data.country
          this.loan_originator_options = result.data.loan_originator.map(lo => lo.common_name)
          this.loan_type_options = result.data.loan_type
          this.loadingFilterHints = false
        })
        .catch(() => {
          this.loadingFilterHints = false
        })
    },
    closeSmsDialog() {
      this.smsDialog = false
      this.$router.push('/autoinvest')
    },
  },
})
</script>
<style>
  .v-icon {
    vertical-align: text-bottom !important;
  }
</style>
