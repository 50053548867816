<template>
  <v-card outlined>
    <v-list subheader flat disabled>
      <v-subheader>{{ $t('autoInvest.investmentListEmptyHeader') }}</v-subheader>
      <v-list-item-group>
        <v-list-item v-for="criterion in investmentCriteria" :key="criterion.name">
          <template #default>
            <v-list-item-icon class="mr-2">
              <v-icon v-if="criterion.value" color="success">mdi-check-circle</v-icon>
              <v-icon v-else>mdi-radiobox-blank</v-icon>
            </v-list-item-icon>
            <v-list-item-content><v-list-item-title v-text="$t(`autoInvest.${criterion.name}`)"/></v-list-item-content>
          </template>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: "AutoInvestCriteriaList",
  props: {
    countInvestments: { type: Number, required: true},
    loans: { type: Array, default: () => { return [] } },
    monetaryConstraints: { type: Object, default: () => { return {} } },
    strategyId: { type: String, default: null },
    loadingInvestments: { type: Boolean, default: false },
  },
  data() {
    return {
      investedAmount: 0,
    }
  },
  computed: {
    investmentCriteria() {
      return [
        { name: 'balanceConstraint',             value: this.balance > 10},
        { name: 'minInvestmentAmountConstraint',
          value: +this.investedAmount < +this.monetaryConstraints.targetPortfolioSize },
        { name: 'verifiedConstraint',            value: this.$auth.user().verification === 'approved' },
        { name: 'matchingLoansAmountConstraint', value: this.loans.length > 0 },
      ]
    },
    balance() {
      return this.$store.state.account?.balance || 0
    },
  },
  mounted() {
    this.fetch()
  },
  methods: {
    fetch() {
      if (this.strategyId && !this.loadingInvestments) {
        this.$http
          .get(`/autoinvest_strategies/${this.strategyId}/`)
          .then((response) => {
            this.investedAmount = response.data.currently_invested_amount
          })
          .catch(() => { return })
      }
    } },
})
</script>
